import { Injectable } from '@angular/core';
import { ID, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Establishment } from 'src/app/shared/objects/establishment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor() { }


  getEstablishments() {
    return Api.database.listDocuments<Establishment>(environment.database, "Establishments", [Query.limit(100)]);
  }


  createEstablishment(establishment: Partial<Establishment>, teamId: string) {
    return Api.database.createDocument(environment.database, "Establishments", ID.unique(), establishment, [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Owner"))]);
  }

  updateEstablishment(establishment: Establishment) {
    return Api.database.updateDocument(environment.database, "Establishments", establishment.$id, this.createPartialEstablishment(establishment))
  }

  getEstablishment(establishementId: string) {
    return Api.database.getDocument<Establishment>(environment.database, "Establishments", establishementId);
  }

  /**
   * This method will return a partial copy to insert into database
   * @param establishment 
   * @returns 
   */
  createPartialEstablishment(establishment: Establishment): Partial<Establishment> {
    if (!establishment) {
      return {};
    }
    return {
      establishmentName: establishment.establishmentName,
      establishmentPhoneNumber: establishment.establishmentPhoneNumber,
      establishmentEmailAdress: establishment.establishmentEmailAdress,
      establishmentAdressStreet: establishment.establishmentAdressStreet,
      establishmentAdressNumber: establishment.establishmentAdressNumber,
      establishmentAdressZipcode: establishment.establishmentAdressZipcode,
      establishmentAdressCity: establishment.establishmentAdressCity,
      isMainEstablishment: establishment.isMainEstablishment,
      establishmentBrandMainColor: establishment.establishmentBrandMainColor,
      establishmentBrandSecondColor: establishment.establishmentBrandSecondColor,
      establishmentPolicies: establishment.establishmentPolicies,
      displayPricesOnSheet: establishment.displayPricesOnSheet,
      displayGuestContactValuesOnSheet: establishment.displayGuestContactValuesOnSheet,
      displayDayOfWeekOnSheet: establishment.displayDayOfWeekOnSheet,
      useInternalDescriptionWhenAvailable: establishment.useInternalDescriptionWhenAvailable,
      showInitialDepositInDashboard: establishment.showInitialDepositInDashboard,
      showExpirationDateWhenAgreed: establishment.showExpirationDateWhenAgreed,
      showWarningDateIsNearAndNotConfirmed: establishment.showWarningDateIsNearAndNotConfirmed,
      determineTenderNameAutomaticaly: establishment.determineTenderNameAutomaticaly,
      logoReference: establishment.logoReference,
      tenderIntroduction: establishment.tenderIntroduction,
      defaultInitialDepositPercentage: establishment.defaultInitialDepositPercentage,
      defaultDaysBeforeExpirering: establishment.defaultDaysBeforeExpirering,
      determineExpirationDateAutomatically: establishment.determineExpirationDateAutomatically,
      hideIncomeStatistics: establishment.hideIncomeStatistics,
      kvkNumber: establishment.kvkNumber,
      linkInvoiceAscriptionAndEmailAdress: establishment.linkInvoiceAscriptionAndEmailAdress,
      showPriceStructureInTender: establishment.showPriceStructureInTender,
      vatId: establishment.vatId,
      bankAccountNumber: establishment.bankAccountNumber,
      defaultPaymentTermInvoices: establishment.defaultDaysBeforeExpirering
    }
  }

  /**
   * This function will upload a file to the bucket
   * @param file 
   * @returns 
   */
  uploadEstablishmentLogo(file: File, teamId: string) {
    return Api.storage.createFile(
      'Assets',
      ID.unique(),
      file,
      [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))]
    );
  }

    /**
   * This function will remove a file from the bucket
   * @param file 
   * @returns 
   */
    removeEstablishmentLogo(fileId: string) {
      return Api.storage.deleteFile(
        'Assets',
        fileId
      );
    }

  /**
   * This method will download the establishment logo
   * @param fileId 
   */
  downloadEstablishmentLogo(fileId: string){
    return Api.storage.getFileDownload("Assets", fileId);
  }

    /**
   * This method will download the establishmentId
   * @param fileId 
   */
    getEstablishmentLogoPreview(fileId: string){
      return Api.storage.getFilePreview("Assets", fileId);
    }
}
